/**
 * Created by sirosh on 22.01.16.
 */
export const constTaxCalculationMethod = [
    {
        id: 'INCLUDED',
        name: 'SETTINGS.TAX.INCLUDED'
    }, {
        id: 'ADDED',
        name: 'SETTINGS.TAX.ADDED'
    }
];