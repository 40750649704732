/**
 * Created by galkina on 13.08.24.
 */
export const constTaxSaVATCategories = [
    {
        id: 'ZERO_RATED_GOODS',
        name: 'SETTINGS.TAX.ZERO_RATED_GOODS'
    }, {
        id: 'EXEMPT_FROM_TAX',
        name: 'SETTINGS.TAX.EXEMPT_FROM_TAX'
    }, {
        id: 'NOT_SUBJECT_TO_VAT',
        name: 'SETTINGS.TAX.NOT_SUBJECT_TO_VAT'
    }
];
