tokensList.$inject = [
    '$tokensList',
    '$scope',
    '$state',
    '$stateParams',
    'serviceToast',
    'factoryTokens',
    'serviceDialog',
    '$filter'
];

function tokensList(
    $tokensList,
    $scope,
    $state,
    $stateParams,
    serviceToast,
    factoryTokens,
    serviceDialog,
    $filter
) {

    var vm = this;
    $scope.tokenslist = {};
    $scope.tokenslist._select = false;
    $scope.showDel = false;
    var canCreateToken = $tokensList.canCreateToken;
    $scope.selectedTokens = [];
    vm.tokensList = $tokensList.authTokens;
    vm.allTokens = false;
    var translate = $filter('translate');
    $scope.editTableState = 'integrations.tokenDetail';

    if ($stateParams.checkFromList.length) {
        $scope.selectedTokens = $stateParams.checkFromList;
        $scope.showDel = true;
        if ($scope.selectedTokens.length === vm.tokensList.length) {
            $scope.tokenslist._select = true;
        }
    }

    //--  data for saving the state of filters and CHECKBOXES
    $scope.stateFromList = $state.params;
    $scope.checkFromList = $stateParams.checkFromList;

    $scope.onAdd = function() {
        if(canCreateToken) {
            $state.go('integrations.createToken', {
                stateToSave: $scope.stateFromList,
                checkToSave: $scope.selectedTokens
            });
        } else {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('TOKENS.UNABLE_ADD_TOKEN_TITLE'),
                content: translate('TOKENS.UNABLE_ADD_TOKEN_TEXT'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        }
    };

    if ($scope.checkFromList.length === undefined || $scope.checkFromList.length === 0) {
        for (let i = 0; i < vm.tokensList.length; i++) {
            vm.tokensList[i]._select = false;
        }
    } else {
        for (let i = 0; i < $scope.checkFromList.length; i++) {

            for (let q = 0; q < vm.tokensList.length; q++) {
                ($scope.checkFromList[i] === vm.tokensList[q].id) ? vm.tokensLists[q]._select = true : "";
                (!vm.tokensLists[q]._select) ? vm.tokensLists[q]._select = false : "";
            }
        }
    }


    $scope.changeAll = function () {
        if ($scope.tokenslist._select === true) {
            for (let k = 0; k < vm.tokensList.length; k++) {
                vm.tokensList[k]["_select"] = true;
                $scope.selectedTokens.push(vm.tokensList[k].id);
            }
            $scope.showDel = true;


        } else {
            for (let m = 0; m < vm.tokensList.length; m++) {
                vm.tokensList[m]["_select"] = false;
                for (let a = 0; a < $scope.selectedTokens.length; a++) {
                    if(vm.tokensList[m].id === $scope.selectedTokens[a]) {
                        $scope.selectedTokens.splice(a,1);
                        a--;
                    }
                }
            }
            $scope.showDel = false;
        }
    };



    $scope.changeItem = function(token) {
        if (token._select) {
            $scope.selectedTokens.push(token.id);
            $scope.showDel = true;

            if ($scope.selectedTokens.length === vm.tokensList.length) {
                $scope.tokenslist._select = true;
            }
        } else {
            $scope.selectedTokens = $scope.selectedTokens.filter(function (selectedTokens) {
                return token.id !== selectedTokens;
            });

            if ($scope.tokenslist._select) $scope.tokenslist._select = false;

            if (!$scope.selectedTokens.length)
                $scope.showDel = false;
        }
    };

    $scope.onDeleleToken = function() {

        let deleteTitle = $scope.selectedTokens.length === 1 ?
            'TOKENS.DELETE_MESS_TITLE' : 'TOKENS.DELETE_MESS_TITLE_MANY';
        let deleteInfo = $scope.selectedTokens.length === 1 ?
            'TOKENS.DELETE_MESSAGE' : 'TOKENS.DELETE_MESSAGE_MANY';

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   translate(deleteTitle),
            content:  translate(deleteInfo),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: deleteToken
                }
            ]
        });
    };

    function deleteToken() {

        let deleteMessage = $scope.selectedTokens.length === 1 ?
            'TOKENS.TOKEN_DELETED' : 'TOKENS.TOKENS_DELETED';

        factoryTokens.deleteApiAuthToken({
            tokenIds: $scope.selectedTokens
        }).then(function (result) {

            if (result === "ok") {
                serviceToast.show(deleteMessage, 'success');
                $state.go($state.current.name, {checkFromList:  []}, {
                                                reload: true
                });
            }
        });
    }

}

export default {
    type: 'controller',
    name: 'tokensList',
    value: tokensList
};
