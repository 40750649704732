import angular from 'angular';

function formDirectiveFactory(isNgForm) {
    createDirective.$inject = [];

    function createDirective() {
        let resultDirective = {
            require: 'form',
            restrict: isNgForm ? 'AEC' : 'E',
            link: linkDirective
        };

        function linkDirective(scope, element, attrs, ctrl) {
            ctrl.focusFirstInvalidControl = focusFirstInvalidControl;
            ctrl.markFirstInvalidControl = markFirstInvalidControl;
            ctrl.validateControls = validateControls;
            ctrl.setInvalidTouchedControls = setInvalidTouchedControls

            function focusFirstInvalidControl(forceSearch) {
                let invalidElements = markFirstInvalidControl(forceSearch);
                if (invalidElements.length === 0) {
                    return;
                }

                let elementToFocus = invalidElements.eq(0);
                convertToDomElement(elementToFocus).focus();
            }

            function markFirstInvalidControl(forceSearch) {
                let invalidElements = element.find('[ng-model].ng-invalid');

                // Just after validation "ng-invalid" class can be not yet applied because of animation
                if (invalidElements.length === 0 && forceSearch) {
                    invalidElements = element.find("[ng-model].is-invalid");
                }
                if (invalidElements.length === 0) {
                    return [];
                }

                angular.forEach(invalidElements, function(invalidElem) {
                    angular.element(invalidElem).controller('ngModel').$setTouched();
                });

                return invalidElements;
            }

            function validateControls() {
                ctrl.$getControls().forEach(ctrl => {
                    if (ctrl.validateControls) {
                        ctrl.validateControls();
                    }
                    if (ctrl.$validate) {
                        ctrl.$validate();
                    }
                });
            }

            function setInvalidTouchedControls() {
                ctrl.$getControls().forEach(ctrl => {
                    if (ctrl.$invalid) {
                        if (ctrl.setInvalidTouchedControls) {
                            ctrl.setInvalidTouchedControls();
                        }
                        if (ctrl.$setTouched) {
                            ctrl.$setTouched();
                        }
                    }
                });
            }
        }

        function convertToDomElement(elem) {
            if (!!elem && !!elem[0]) {
                return elem[0];
            }
            return elem;
        }

        return resultDirective;
    }

    return createDirective;
}

export default [
    {
        type: 'directive',
        name: 'form',
        value: formDirectiveFactory()
    },
    {
        type: 'directive',
        name: 'ngForm',
        value: formDirectiveFactory(true)
    }
];
