/**
 * Created by sirosh on 14.01.16.
 * @memberof createtaxCtrl
 * @ngdoc controller
 * @name createtaxCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter servise
 * @requires $state {service} UI Router servise
 * @requires $stateParams {service} UI Router servise
 * @requires constTaxOptions {service} Tax types
 * @requires constTaxActions {service}
 * @requires getTaxList {service} Get tax list / operation with tax
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 */

import angular from 'angular';
import { constTaxRatePredefined } from '../../../constant/constant-tax-rate-predefined';
import {constTaxSaVATCategories} from '../../../constant/constant-tax-sa-VAT-categories';
import {constTaxSaVATExemptReason} from '../../../constant/constant-tax-sa-VAT-exemption-reason';
import {constTaxType} from '../../../constant/constant-tax-type';
import {constTaxCalculationMethod} from "../../../constant/constant-tax-calculation-method";

createtaxCtrl.$inject = [
		"$scope",
		"$rootScope", 
		"$state", 
		"$stateParams", 
		"$filter",
		"constTaxOptions", 
		"getTaxList", 
		"serviceDialog", 
		"serviceToast", 
		"$profile", 
		"$tax", 
		"$taxById",
		"$shortOutlets",
		"$timeout",
		"$percentShowTax",
		'$translate',
		'dialogs'];
function createtaxCtrl (
			$scope,
			$rootScope,
			$state,
			$stateParams,
			$filter,
			constTaxOptions,
			getTaxList,
			serviceDialog,
			serviceToast,
			$profile,
			$tax,
			$taxById,
			$shortOutlets,
			$timeout,
			$percentShowTax,
			$translate,
			dialogs) {

	var vm = this;
	var hasTax;
	var translate = $filter( 'translate' );
	$scope.emptyValue = null;

	let initParams;
	let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
	function isChanged() {
		let currentParams = {
			type: $scope.tax.type,
			taxName: $scope.tax.taxName,
			value: $scope.models.value,
			selectType: $scope.options.selectType,
			selectAction: $scope.options.selectAction,
			selectTaxType: $scope.options.selectTaxType,
			selectVatCategory: $scope.options.selectVatCategory,
			selectVatExemptReason: $scope.options.selectVatExemptReason,
			vatExemptReasonText: $scope.options.vatExemptReasonText,
			useFiscal: vm.useFiscal,
			taxGroup: $scope.tax.taxGroup,
			diningOptionDependent: $scope.tax.diningOptionDependent,
			diningOptions: angular.copy(vm.diningOptions),
			categorieList: angular.copy(vm.categorieList),
			outlets: $scope.outlets.map(item => {
				return {
					enable: item.enable,
					select: item._select
				};
			})
		};
		return !angular.equals(initParams, currentParams);
	}

	$scope.profile = angular.copy($profile);
	$scope.useSATaxation = $profile.features.saNewTaxationEnabled;
	var categoryList;
	$scope.inCategory = false;
	$scope.title = '';
	$scope.tax = {};
	$scope.models = {};
	$scope.options = {};
	$scope.optionsType = constTaxCalculationMethod.map(val => (
		{
			id: val.id,
			name: $translate.instant(val.name)
		}
	));
	$scope.onlyIncludedType = [{
		id: "INCLUDED",
		name: $translate.instant("SETTINGS.TAX.INCLUDED")
	}];
	$scope.optionsActions = constTaxOptions.map(tax => (
		{
			id: tax.id,
			name: $translate.instant(tax.name)
		}
	));
	$scope.optionsTaxType = constTaxType.map(tax => ({
		    id: tax.id,
			name: $translate.instant(tax.name)
		})
	);
	$scope.optionsTaxRateVat = constTaxRatePredefined.VAT.map(rate => (
		{
			id: rate.toString(),
			value: `${rate} %`
		}
	));
	$scope.optionsTaxRateExcise = constTaxRatePredefined.EXCISE.map(rate => (
		{
			id: rate.toString(),
			value: `${rate} %`
		}
	));
	$scope.constTaxSaVATCategories = constTaxSaVATCategories.map(val => (
		{
			id: val.id,
			name: $translate.instant(val.name)
		}
	));
	$scope.constTaxSaVATExReasonZero = constTaxSaVATExemptReason.ZERO_RATED_GOODS.map(val => (
		{
			id: val,
			name: $translate.instant('SETTINGS.TAX.' + val)
		}
	));
	$scope.constTaxSaVATExReasonFromTax = constTaxSaVATExemptReason.EXEMPT_FROM_TAX.map(val => (
		{
			id: val,
			name: $translate.instant('SETTINGS.TAX.' + val)
		}
	));
	$scope.clicked = true;
	$scope.profile = $profile;
	$scope.percentShowTax = $percentShowTax;
	$scope.itemText = itemText;
	$scope.dialogisOpen = false;
	vm.ceshCategories = [];
	vm.ceshItems = [];
	vm.optionsSelected = [];
	$scope.searchItemsList = [];
	$scope.searchText = "";
	$scope.categorySearch = "";
	$scope.progress = false;
	var beforeSaveCategories = [],
		beforeSaveItems = [],
		ifNeedToReCheck = [],
		enterData = [];

	function getTaxGroupList() {
		var _g = [],
			length_g = 9,
			alphabetFirstSeven = ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
			romanaGroups = ['A', 'B', 'C', 'D', 'E', '#', 'S'],
			greeceGroups = ["Α", "Β", "Γ", "Δ", "Ε"];

		if($profile.country === "pl" || $profile.country === "ro") {
			length_g = 7;
		} else if($profile.country === "sk" || $profile.country === "ru") {
			length_g = 6;
		} else if($profile.country === "gr") {
			length_g = 5;
		}

		for(var j = 0; j < length_g; j++) {
			_g.push({
				key: j + 1,
				name: length_g > alphabetFirstSeven.length ? j + 1 : $profile.country === "ro" ? romanaGroups[j] : $profile.country === "gr" ? greeceGroups[j] : alphabetFirstSeven[j]
			})
		}

		return _g;
	}

	if ( $state.current.name === 'createtax' ) {
		$scope.title = 'SETTINGS.TAX.CREATE_TAX';
		$scope.options.selectType = 'INCLUDED';
		$scope.options.selectAction = 'NEW';
		$scope.options.selectTaxType = 'DEFAULT';
		$scope.options.selectVatCategory = 'ZERO_RATED_GOODS';
		$scope.options.selectVatExemptReason = null;
		$scope.options.vatExemptReasonText = null;
		$scope.options.showDelBtn = false;
		$scope.models.value = null;
		vm.useFiscal = false;
		vm.categoriesSelected = 0;

		$scope.groups = getTaxGroupList();

		$scope.tax = {
			type: "INCLUDED",
			taxName: '',
			value: '',
			taxType: 'DEFAULT',
			applyToAllWares: false,
			applyForAllNewWares: true,
			isPrintedToFiscal: false,
			diningOptionDependent: false,
			taxGroup: $scope.groups[0].key,
			diningOptions: [],
			exemptCategories: [],
			exemptItems: [],
			saVATCategory: null,
			saVATExemptionReason: null,
			saVATExemptionReasonText: null
		};
	} else {
		$scope.title = 'SETTINGS.TAX.EDIT_TAX';
		$scope.options.showDelBtn = true;
		$scope.groups = getTaxGroupList();
		$scope.taxIds = [];
		$scope.taxIds.push( $taxById.id );
		$scope.waresCount = $taxById.waresCount;
		$scope.tax.taxName = $taxById.name;
		$scope.models.value = $filter( 'taxPercentFormatShowInEdit' )( $taxById.value );
		$scope.tax.type = $taxById.type;
		$scope.options.selectType = $scope.tax.type;
		$scope.tax.taxId = $taxById.id;
		$scope.tax.isPrintedToFiscal = $taxById.taxGroup !== null;
		$scope.tax.taxType = $taxById.taxType;
		$scope.options.selectTaxType = $scope.tax.taxType;
		$scope.tax.saVATCategory = $taxById.saVATCategory;
		$scope.options.selectVatCategory = $scope.tax.saVATCategory;
		$scope.tax.saVATExemptionReason = $taxById.saVATExemptionReason;
		$scope.options.selectVatExemptReason = $scope.tax.saVATExemptionReason;
		$scope.tax.saVATExemptionReasonText = $taxById.saVATExemptionReasonText;
		$scope.options.vatExemptReasonText = $scope.tax.saVATExemptionReasonText;
		$scope.tax.allowedForAllOutlets = $taxById.allowedForAllOutlets;
		$scope.tax.byOutlets = $taxById.byOutlets;
		$scope.tax.diningOptionDependent = ($scope.profile.useDiningOptions) ? $taxById.diningOptionDependent : false;
		$scope.tax.exemptCategories = [];
		$scope.tax.exemptItems = [];
		$scope.tax.diningOptions = [];
		vm.useFiscal = $taxById.taxGroup !== null;
		vm.categoriesSelected = $taxById.checkedItemsCount;
		vm.diningOptions = angular.copy($taxById.diningOptions);
		vm.diningOptions.forEach(function(option) {
			if (option.name === null) {
				option.name = translate('DINING.' + option.type);
			}
			if(option.checked === true) vm.optionsSelected.push(option);
		});
		$scope.tax.taxGroup = (function () {
			if ( $taxById.taxGroup ) {
				return $taxById.taxGroup;
			} else {
				return $scope.groups[0].key;
			}
		})();

		if($scope.useSATaxation) {
			const isOldTax = $scope.optionsTaxRateVat.find(
				tax => tax.id === $scope.models.value
			)
			const isOldExciseTax = $scope.optionsTaxRateExcise.find(
				tax => tax.id === $scope.models.value
			)
			if (!isOldTax && !isOldExciseTax) {
				$scope.emptyValue = {
					id: $scope.models.value,
					value: `${$scope.models.value} %`
				}
			}
		}

		if ( $taxById.applyForAllNewWares === false ) {
			$scope.options.selectAction = 'NONE';
		} else {
			$scope.options.selectAction = 'NEW';
		}
	}

//** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ
	$scope.state = $stateParams;

	if ( $stateParams.id ) {
		// console.log("$scope.tax в редактировании = ", $scope.tax);
		$scope.outlets = $scope.tax.byOutlets;
		// console.log("$scope.outlets в редактировании = ", $scope.outlets);
	} else {
		$scope.outlets = $shortOutlets;
		// console.log("$scope.outlets в создании = ", $scope.outlets);
	}



	$scope.waresCount = 0;
	$scope.outletSelect = [];
	$scope.allOutlets = {};
	// console.log("$scope.outlets = ", $scope.outlets);
	// console.log("$scope.outletSelect = ", $scope.outletSelect);
	// console.log("$scope.allOutlets = ", $scope.allOutlets);

	$scope.$watch('outlets', function(newVal){

		for(var j = 0; j < newVal.length; j++){
			if ( $stateParams.id ) {
				if(newVal[j].enable === true){
					$scope.outletSelect.push(newVal[j].outletId);
					$scope.waresCount += newVal[j].waresCount;
				}
			} else {
				if(newVal[j]._select === true){
					$scope.outletSelect.push(newVal[j].id);
					$scope.waresCount += newVal[j].waresCount;
				}
			}
		}
		// console.log("$scope.outlets = ", $scope.outlets);
		// console.log("$scope.outletSelect = ", $scope.outletSelect);

		if($scope.outletSelect.length === $scope.outlets.length ){
			$scope.allOutlets._select = true;
		}else{
			$scope.allOutlets._select = false;
		}

		// ложим айдишники выбранных заведений в свойство обьекта tax для отправки на сервер
		$scope.tax.outletsIds = $scope.outletSelect;
		$scope.tax.allowedForAllOutlets = $scope.allOutlets._select;

		$scope.outletSelect = [];
	}, true);


	for(var i = 0; i < $scope.outlets.length; i++){
		$scope.outlets[i]["_select"] = true;
	}

	initParams = {
		type: $scope.tax.type,
		taxName: $scope.tax.taxName,
		value: $scope.models.value,
		selectType: $scope.options.selectType,
		selectTaxType: $scope.options.selectTaxType,
		selectVatCategory: $scope.options.selectVatCategory,
		selectVatExemptReason: $scope.options.selectVatExemptReason,
		vatExemptReasonText: $scope.options.vatExemptReasonText,
		selectAction: $scope.options.selectAction,
		useFiscal: vm.useFiscal,
		taxGroup: $scope.tax.taxGroup,
		diningOptionDependent: $scope.tax.diningOptionDependent,
		diningOptions: angular.copy(vm.diningOptions),
		categorieList: angular.copy(vm.categorieList),
		outlets: $scope.outlets.map(item => {
			return {
				enable: item.enable,
				select: item._select
			};
		})
	};

	$scope.changeAll = function(){
		if($scope.allOutlets._select === true){
			for(var k = 0; k < $scope.outlets.length; k++){
				if ( $stateParams.id ) {
					$scope.outlets[k]["enable"] = true;
				} else {
					$scope.outlets[k]["_select"] = true;
				}
			}
			$scope.allOutlets._select = true;
			// console.log("$scope.outlets = ", $scope.outlets);
			// console.log("$scope.allOutlets = ", $scope.allOutlets);
		}else{
			for(var m = 0; m < $scope.outlets.length; m++){
				if ( $stateParams.id ) {
					$scope.outlets[m]["enable"] = false;
				} else {
					$scope.outlets[m]["_select"] = false;
				}
			}
			$scope.allOutlets._select = false;
			// console.log("$scope.outlets = ", $scope.outlets);
			// console.log("$scope.allOutlets = ", $scope.allOutlets);
		}
	};
//** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ


//** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
	$scope.showLine = true; // показывать линию в главном блоке
	$scope.showAllOutlets = false;   // показывать весь блок с заведениями
	$scope.singleDisabledOutlet = $scope.outlets.length === 1 && $scope.outlets[0].enable === false;

	$scope.showBlock = function (flag) {
		$scope.showAllOutlets = flag;
		$scope.showLine = !flag;
		$scope.singleDisabledOutlet = !flag;
	};
//** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА


	$scope.onSave = function () {

		if ( $scope.clicked == false ) {
			return false;
		}

		if ($scope.setTax.$invalid) {
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			$scope.setTax.focusFirstInvalidControl();
			return false;
		}

		function send() {
			$scope.clicked = false;

			// console.log("$scope.tax from send -1 =", $scope.tax);
			// console.log("$scope.models =", $scope.models);

			$scope.tax.value = $filter('filterTaxPercentFormatSave')($scope.models.value);

			// console.log("$scope.tax from send -2 =", $scope.tax);

			$scope.tax.type = $scope.options.selectType;
			$scope.tax.taxType = $scope.options.selectTaxType;
			$scope.tax.saVATCategory = $scope.tax.value === "000000" ? $scope.options.selectVatCategory : null;
			$scope.tax.saVATExemptionReason = $scope.options.selectVatCategory === "NOT_SUBJECT_TO_VAT" ? "VATEX-SA-OOS" : $scope.options.selectVatExemptReason;
			$scope.tax.saVATExemptionReasonText = $scope.options.selectVatCategory === "NOT_SUBJECT_TO_VAT" ? $scope.options.vatExemptReasonText : null;

			if($scope.tax.diningOptionDependent) {
				if(vm.diningOptions) {
					vm.diningOptions.forEach(function (option) {
						if (option.checked) {
							var doInd = $scope.tax.diningOptions.indexOf(option.id);
							if (doInd === -1) {
								$scope.tax.diningOptions.push(option.id);
							}
						}
					});
				}
				if(vm.startingDataCategories && beforeSaveCategories && beforeSaveCategories.length) {
					vm.startingDataCategories.forEach(function (category) {
						for (var i = 0; i < beforeSaveCategories.length; i++) {
							if (category.id === beforeSaveCategories[i].id && (category.selectedCount !== beforeSaveCategories[i].selectedCount || (category.dependentNewItems !== null && category.dependentNewItems !== beforeSaveCategories[i].dependentNewItems)
										|| (category.dependentNewItems === null && beforeSaveCategories[i].dependentNewItems === true))) {
								if ((beforeSaveCategories[i].selectedCount === beforeSaveCategories[i].totalCount && beforeSaveCategories[i].dependentNewItems) || (category.dependentNewItems !== beforeSaveCategories[i].dependentNewItems) || beforeSaveCategories[i].partial) {
									$scope.tax.exemptCategories.push({
										id: beforeSaveCategories[i].id,
										checked: beforeSaveCategories[i].checked,
										dependentNewItems: beforeSaveCategories[i].dependentNewItems
									})
								}
							}
						}
					});
				}
				if(beforeSaveItems.length) {
					$scope.tax.exemptItems = beforeSaveItems.map(function (item) {
						return {
							id: item.id,
							checked: item.selected
						}
					});
				}
			}

			// console.log("$scope.tax = ", $scope.tax);


			var opt = {
				'NEW': function () {
					$scope.tax.applyForAllNewWares = true;
					$scope.tax.applyToAllWares = false;
				},
				'OLD': function () {
					$scope.tax.applyForAllNewWares = false;
					$scope.tax.applyToAllWares = true;
				},
				'OLD_NEW': function () {
					$scope.tax.applyForAllNewWares = true;
					$scope.tax.applyToAllWares = true;
				},
				'NONE': function () {
					$scope.tax.applyForAllNewWares = false;
					$scope.tax.applyToAllWares = false;
				}
			};

			opt[$scope.options.selectAction]();

			if ( $state.current.name === 'createtax' ) {
				// console.log("$scope.tax = ", $scope.tax);
				$scope.tax.isPrintedToFiscal = vm.useFiscal;
				$scope.tax.taxGroup = vm.useFiscal ? $scope.tax.taxGroup : null;
				$scope.progress = true;
				getTaxList.setTax( $scope.tax ).then( function ( res ) {

					if ( res.result === "ok" && !res.message ) {
						serviceToast.show( 'SETTINGS.TAX.TOSTS.CREATE_TAX', 'success' );
						offStateChangeStart();
						$state.go( 'settings.tax', $stateParams, {
							reload: true
						} );
					} else if(res.result === "error" && res.message === 'tax_exists'){
						hasTax && serviceToast.hide(hasTax);
						serviceDialog.add({
							templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
							title      : translate('SETTINGS.TAX.ERR_CREATE_TAX'),
							content    : translate('SETTINGS.TAX.ERR_TAX_EXIST_TEXT'),
							buttons    : [
								{
									class : 'primary',
									text  : 'ON_OK'
								}
							]
						});
						$scope.clicked = true;
					} else if(res.result === "ok" && res.message === 'cannot_same_tax_type'){
						hasTax && serviceToast.hide(hasTax);
						serviceDialog.add({
							templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
							title      : translate('VALIDATE.ERROR.UNABLE_TO_SAVE'),
							content    : translate('SETTINGS.TAX.ERR_TWO_TAX_SAME_TYPE'),
							buttons    : [
								{
									class : 'primary',
									text  : 'ON_OK'
								}
							]
						});
						$scope.clicked = true;
					} else if(res.result === "ok" && res.message === 'wares_has_same_tax_type'){
						hasTax && serviceToast.hide(hasTax);
						serviceDialog.add({
							templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
							title      : translate('VALIDATE.ERROR.UNABLE_TO_SAVE'),
							content    : translate('SETTINGS.TAX.ERR_ONE_WARE_SAME_TAX_TYPE'),
							buttons    : [
								{
									class : 'primary',
									text  : 'ON_OK'
								}
							]
						});
						$scope.clicked = true;
					} else if ( res.message ) {
						$scope.clicked = true;
						showErrorAlert( res.message )
					}
				},
				function () {
					// console.log("$scope.tax = ", $scope.tax);
					$scope.clicked = true;
				})
				.finally(() => {
					$scope.progress = false;
				})
			} else {
				$scope.tax.isPrintedToFiscal = vm.useFiscal;
				$scope.tax.taxGroup = vm.useFiscal ? $scope.tax.taxGroup : null;
				$scope.progress = true;
				getTaxList.editTax( $scope.tax ).then( function ( res ) {
					if ( res.result === "ok" && !res.message ) {
						serviceToast.show( 'SETTINGS.TAX.TOSTS.EDIT_TAX', 'success' );
						offStateChangeStart();
						backToList();
					} else if(res.result === "ok" && res.message === 'cannot_same_tax_type'){
						hasTax && serviceToast.hide(hasTax);
						serviceDialog.add({
							templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
							title      : translate('VALIDATE.ERROR.UNABLE_TO_SAVE'),
							content    : translate('SETTINGS.TAX.ERR_TWO_TAX_SAME_TYPE'),
							buttons    : [
								{
									class : 'primary',
									text  : 'ON_OK'
								}
							]
						});
						$scope.clicked = true;
					} else if(res.result === "ok" && res.message === 'wares_has_same_tax_type'){
						hasTax && serviceToast.hide(hasTax);
						serviceDialog.add({
							templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
							title      : translate('VALIDATE.ERROR.UNABLE_TO_SAVE'),
							content    : translate('SETTINGS.TAX.ERR_ONE_WARE_SAME_TAX_TYPE'),
							buttons    : [
								{
									class : 'primary',
									text  : 'ON_OK'
								}
							]
						});
						$scope.clicked = true;
					} else if(res.result === "error") {
						if (res.message === 'tax_exists') {
							hasTax && serviceToast.hide(hasTax);

							serviceDialog.add({
								templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
								title      : translate('SETTINGS.TAX.ERR_EDIT_TAX'),
								content    : translate('SETTINGS.TAX.ERR_TAX_EXIST_TEXT'),
								buttons    : [
									{
										class : 'primary',
										text  : 'ON_OK'
									}
								]
							});
							$scope.clicked = true;
						}
						if (res.message === 'tax_deleted') {
							offStateChangeStart();
							dialogs.itemDeletedEarlier().then(backToList);
						}
					} else if ( res.message ) {
						$scope.clicked = true;
						showErrorAlert( res.message )
					}
				},
				function () {
					// console.log("$scope.tax = ", $scope.tax);
					$scope.clicked = true;
				} )
				.finally(() => {
					$scope.progress = false;
				});
			}
		}

		function checkTaxGroup() {
			var sameTaxgroup = false;
			if ($scope.tax.isPrintedToFiscal || vm.useFiscal) {
				var i = 0;
				while (i < $tax.taxes.length) {
					if ((!$scope.tax.taxId || $tax.taxes[i].id !== $scope.tax.taxId) && $tax.taxes[i].taxGroup === $scope.tax.taxGroup) {
						sameTaxgroup = true;
					}
					i++;
				}
				if(sameTaxgroup === true) {
					serviceDialog.add({
						templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
						content: translate('SETTINGS.TAX.SAME_TAX_GOUP'),
						buttons: [
							{
								class: 'primary',
								text: 'ON_OK'
							}
						]
					});
				} else {
					send();
				}
			} else {
				send();
			}
		}


		if ( $scope.options.selectAction === "OLD" || $scope.options.selectAction === "OLD_NEW" ) {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate( 'SETTINGS.TAX.CREATE_TAX_MSG' ),
				content: translate( 'SETTINGS.TAX.CREATE_TAX_MSG_TEXT' ),
				buttons: [
					{
						text: 'ON_CANCEL'
					},
					{
						class: 'primary',
						text: 'ON_OK',
						action: checkTaxGroup
					}
				]
			} );
		} else {
			checkTaxGroup();
		}
	};

	function showErrorAlert( message ) {
		var decodeMessage = {
			'cannot_be_applied': 'CANNOT_BE_APPLIED',
			'too_many_taxes': 'TOO_MANY_TAXES'
		};


		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate( 'ATTENTION' ),//
			content: translate( decodeMessage[message] ),//
			buttons: [
				{
					class: 'primary',
					text: 'ON_OK'
				}
			]
		} );

	}

	$scope.onCancel = function () {
		$state.go( 'settings.tax', $stateParams, {} );
	};

	$scope.onDel = function () {

		$scope.onSendData = function () {
			getTaxList.deleteTax( {taxIds: $scope.taxIds} ).then( function ( data ) {
				if ( data.result === "ok" ) {
					offStateChangeStart();
					serviceToast.show( 'SETTINGS.TAX.TOSTS.DELETED_TAX_ONE', 'success' );
					backToList();
				}
				if (data.result === 'error' && data.message === 'tax_deleted')	{
					offStateChangeStart();
					dialogs.itemDeletedEarlier().then(backToList);
				}
			} );
		};

		if ( $scope.waresCount === 0 ) {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate( 'SETTINGS.TAX.DELETE_TAX_TITLE_ONE' ),
				content: translate( 'SETTINGS.TAX.DELETE_MESSAGE_NO_WARES_ONE' ),
				buttons: [
					{
						id: 'tax_create-delete_dialog-cancel-button',
						text: 'ON_CANCEL'
					},
					{
						id: 'tax_create-delete_dialog-ok-button',
						class: 'primary',
						text: 'ON_OK',
						action: $scope.onSendData
					}
				]
			} );
		} else {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate( 'SETTINGS.TAX.DELETE_TAX_TITLE_ONE' ),
				content: translate( 'SETTINGS.TAX.DELETE_MESSAGE_HAS_WARES_ONE' ),
				buttons: [
					{
						id: 'tax_create-delete_dialog-cancel-button',
						text: 'ON_CANCEL'
					},
					{
						id: 'tax_create-delete_dialog-ok-button',
						class: 'primary',
						text: 'ON_OK',
						action: $scope.onSendData
					}
				]
			} );
		}
	};

	$scope.selectDining = function(){
		if ( $scope.clicked == false ) {
			return false;
		}
		if ( $state.current.name === 'createtax' && !vm.diningOptions) {
			getTaxList.getDiningOptionsListForTaxDependence().then(function (res) {
				if (res.result === "ok") {
					vm.diningOptions = angular.copy(res.diningOptions);
					optionsDialog();
				}
			});
		} else {
			optionsDialog();
		}
	};
	function optionsDialog(){
		$scope.dialogisOpen = true;
		vm.diningOptions.forEach(function (option) {
			option.checked = (option.checked) ? option.checked : false;
			if (option.name === null) {
				option.name = translate('DINING.' + option.type);
			}
		});
		enterData = angular.copy(vm.diningOptions);
		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/tax-depend-on-dining/select-dining-options.html',
			title: translate('SETTINGS.TAX.SELECT_DINING'),
			parents: vm.diningOptions,
			parentsSelected: vm.optionsSelected,
			checkParents: checkOption,
			buttons: [
				{
					text: 'ON_CANCEL',
					action: function () {
						vm.diningOptions = angular.copy(enterData);
						vm.optionsSelected = [];
						vm.diningOptions.forEach(function(option) {
							if(option.checked) {
								vm.optionsSelected.push(option);
							}
						});
						$scope.dialogisOpen = false;
					}
				},
				{
					text: 'READY',
					action: function () {
						$scope.dialogisOpen = false;
					}
				}
			]
		});
	}

	$scope.selectCategory = function(){
		if ( $scope.clicked == false ) {
			return false;
		}
		$scope.dialogisOpen = true;
		getTaxList.getCategoriesListForDiningOptions({taxId: $stateParams.id}).then(function(res){
			// console.log(res);
			if(res.result === "ok") {
				vm.startingDataCategories = angular.copy(res.categories);
				if(!vm.ceshCategories.length && !vm.ceshItems.length) {
					vm.categorieList = angular.copy(res.categories);
					vm.categorieList.forEach(function(category) {
						if (category.totalCount === category.selectedCount && category.dependentNewItems === true) {
							category.checked = true;
						} else {
							category.checked = false;
						}
					});
				} else {
					vm.newCategorieList = angular.copy(res.categories);
					vm.categorieList = (ifNeedToReCheck.length) ? angular.copy(ifNeedToReCheck) : vm.categorieList;
					vm.categorieList.forEach(function (category) {
						for(var j = 0; j < vm.newCategorieList.length; j++) {
							if ((category.id === vm.newCategorieList[j].id) && (category.totalCount !== vm.newCategorieList[j].totalCount)) {
								category.totalCount = vm.newCategorieList[j].totalCount;
								if(category.checked === true && (category.totalCount !== category.selectedCount || category.dependentNewItems === false)) {
									category.checked = false;
								} else if(category.checked === false && category.totalCount === category.selectedCount && category.dependentNewItems === true) {
									category.checked = true;
								}
							}
						}
					});
				}
				categoryList = serviceDialog.add({
					scope: $scope,
					templateUrl: 'dashboard-page/templates/dialog/tax-depend-on-dining/dialog-select-categorys.html',
					parents: vm.categorieList,
					itemsList: openItemList,
					ceshCategories: vm.ceshCategories,
					checkParents: checkCategory,
					itemText: itemText,
					searchText: vm.searchText,
					arrowBack: arrowBackFunction,
					checkItems: recountAll,
					checkSearchedItem: checkSearchedItem,
					allNewInCategory: allNewInCategory,
					lang: $scope.profile.cabinetLang,
					buttons: [
						{
							text: 'ON_CANCEL',
							action: function() {
								vm.categorieList = angular.copy(res.categories);
								vm.ceshCategories = (beforeSaveCategories.length) ? angular.copy(beforeSaveCategories) : [];
								vm.ceshItems = (beforeSaveItems.length) ? angular.copy(beforeSaveItems) : [];
								if(ifNeedToReCheck.length){
									vm.categoriesSelected = 0;
									ifNeedToReCheck.forEach(function(category){
										vm.categoriesSelected += category.selectedCount;
									})
								} else {vm.categoriesSelected = $taxById.checkedItemsCount ? $taxById.checkedItemsCount : 0}
								$scope.searchItemsList.splice(0, $scope.searchItemsList.length);
								$scope.searchText = "";
								$scope.categorySearch = "";
								$scope.itemsList = [];
								$scope.inCategory = false;
								$scope.dialogisOpen = false;
							}
						},
						{
							text: 'READY',
							action: function() {
								if($scope.inCategory === true) arrowBackFunction($scope.currentCategory);
								beforeSaveCategories = angular.copy(vm.ceshCategories);
								ifNeedToReCheck = angular.copy(vm.categorieList);
								// if($scope.searchItemsList.length > 0) {
								//     var selectedSearchedItems = $scope.searchItemsList.filter(function (item) {
								//         return item.selected === true;
								//     });
								//     vm.ceshItems = vm.ceshItems.concat(selectedSearchedItems);
								//     vm.categoriesSelected = vm.ceshItems.length;
								// }
								beforeSaveItems = angular.copy(vm.ceshItems);
								$scope.searchItemsList.splice(0, $scope.searchItemsList.length);
								$scope.searchText = "";
								$scope.categorySearch = "";
								$scope.dialogisOpen = false;
							}
						}
					]
				});
			}
		});
	};

	$scope.searchItems = function(text) {
		getTaxList.searchItemsListForDiningOptions({search: text, taxId: $stateParams.id}).then(function (res) {
			if(res.result === "ok") {
				$scope.searchItemsList = res.items.map(function (item) {
					var selected = item.selected;
					for(var i = 0, length = $scope.searchItemsList.length; i < length; i++) {
						if(item.id === $scope.searchItemsList[i].id && item.selected !== $scope.searchItemsList[i].selected) {
							selected = $scope.searchItemsList[i].selected;
							break;
						}
					}
					for(var j = 0, l = vm.ceshItems.length; j < l; j++) {
						if(item.id === vm.ceshItems[j].id && item.selected !== vm.ceshItems[j].selected) {
							selected = vm.ceshItems[j].selected;
							break;
						}
					}
					return {
						id: item.id,
						name: item.name,
						categoryId: item.categoryId,
						selected: selected
					}
				});
			}
		})
	};

	function openItemList(category) {
		$scope.isLoading = true;
		$scope.inCategory = true;
		// serviceDialog.close(categoryList);
		$scope.categoryName = category.name;
		$scope.currentCategory = category;
		if(category.partial) category.partial = false;
		getTaxList.getItemsListForDiningOptions({
			taxId: $stateParams.id,
			categoryId: category.id
		}).then(function (res) {
			if (res.result === "ok") {
				$scope.itemsList = angular.copy(res.items);
				vm.forEvoidDubles = angular.copy(res.items);
				if(vm.ceshItems.length){
					$scope.itemsList.forEach(function(ware){
						for(var a = 0; a < vm.ceshItems.length; a++) {
							if(ware.id === vm.ceshItems[a].id && ware.selected !== vm.ceshItems[a].selected) {
								ware.selected = vm.ceshItems[a].selected;
							}
						}
					})
				}
				if(vm.ceshCategories.length) {
					vm.ceshCategories.forEach(function(cat){
						if (cat.id === category.id && category.selectedCount === category.totalCount) {
							$scope.itemsList.forEach(function (ware) {
								if (!ware.selected) vm.ceshItems.push(ware);
								ware.selected = true;
							})
						} else if (cat.id === category.id && category.selectedCount === 0){
							$scope.itemsList.forEach(function (ware) {
								if (ware.selected) vm.ceshItems.push(ware);
								ware.selected = false;
							})
						}
					});
				}
				$scope.isLoading = false;
			}
		})
	}

	function arrowBackFunction(category) {
		$scope.inCategory = false;
		var wathEmpty = false;
		$scope.categorySearch = "";
		vm.startingDataCategories.forEach(function(categoryFromStart){
			if(categoryFromStart.id === category.id && categoryFromStart.selectedCount === 0) {
				wathEmpty = true;
			}
		});
		category.selectedCount = 0;
		$scope.itemsList.forEach(function (item) {
			if (item.selected) category.selectedCount++;
		});
		if (category.selectedCount !== category.totalCount || (category.selectedCount === category.totalCount && category.dependentNewItems === false)) {
			category.checked = false;
			if(category.fullItemList){
				$scope.itemsList.forEach(function (item) {
					var idxItem = checkIfIn(item, vm.ceshItems);
					if (idxItem === -1) {
						vm.ceshItems.push(item);
					}
				});
			}
		} else if (category.selectedCount === category.totalCount && category.dependentNewItems === true) {
			category.checked = true;
			if(wathEmpty) {
				$scope.itemsList.forEach(function (item) {
					var idxItem = checkIfIn(item, vm.ceshItems);
					vm.ceshItems.splice(idxItem, 1);
				});
				var ind = checkIfIn(category, vm.ceshCategories);
				if (ind === -1) {
					vm.ceshCategories.push(category);
				}
			}
		}
		$scope.itemsList = [];
		// $scope.selectCategory()
	}

	/* Function for counting items in count by category or by supplier and comparing them with total amount of supplier's or category's items */

	function allNewInCategory(forAll, category) {
		// category.dependentNewItems = forAll;
		if(!forAll) {
			category.checked = false;
		}
		var ind = checkIfIn(category, vm.ceshCategories);
		if(ind > -1) {
			vm.ceshCategories.splice(ind, 1);
			category.fullItemList = false;
			if(vm.ceshItems.length) {
				vm.forEvoidDubles.forEach(function (ware) {
					for (var i = 0; i < vm.ceshItems.length; i++){
						if(ware.id === vm.ceshItems[i].id && ware.selected === vm.ceshItems[i].selected){
							vm.ceshItems.splice(i, 1);
						}
					}
				});
			}
		} else {
			vm.ceshCategories.push(category);
			category.fullItemList = true;
		}
	}

	function checkCategory (item) {
		var ind = checkIfIn(item, vm.ceshCategories);
		if (ind > -1) {
			if(!item.partial) vm.ceshCategories.splice(ind, 1);
		} else {
			vm.ceshCategories.push(item);
			if(item.selectedCount !== 0 && (item.selectedCount !== item.totalCount || item.dependentNewItems === false)) item.partial = true;
		}
		if(!item.checked){
			item.selectedCount = 0;
			vm.categoriesSelected -= item.totalCount;
			item.dependentNewItems = false;
		} else {
			if(vm.ceshItems.length){
				for(var j = 0; j < vm.ceshItems.length; j++){
					if(vm.ceshItems[j].categoryId === item.id){
						vm.ceshItems.splice(j, 1);
						j--;
					}
				}
			}
			if(item.selectedCount !== item.totalCount || item.dependentNewItems === false){
				vm.categoriesSelected -= item.selectedCount;
			}
			item.selectedCount = item.totalCount;
			vm.categoriesSelected += item.totalCount;
			item.dependentNewItems = true;
		}

	}

	function checkIfIn(item, array){
		for(var i = 0; i < array.length; i++){
			if(array[i].id === item.id) return i;
		}
		return -1;
	}

	function recountAll(item, categoryId){
		var ind = checkIfIn(item, vm.ceshItems);
		var searchedInd = checkIfIn(item, $scope.searchItemsList);
		if (ind > -1) {
			vm.ceshItems.splice(ind, 1);
			if(searchedInd > -1) {
				$scope.searchItemsList.splice(searchedInd, 1);
			}
		} else {
			item.categoryId = categoryId;
			vm.ceshItems.push(item);
		}
		if(!item.selected){
			vm.categoriesSelected -= 1;
		} else {
			vm.categoriesSelected += 1;
		}
	}

	function checkOption(option) {
		var idx = vm.optionsSelected.indexOf(option);
		if (idx > -1) {
			vm.optionsSelected.splice(idx, 1);
		}
		else {
			vm.optionsSelected.push(option);
		}
	}

	function checkSearchedItem(item) {
		// recountAll(item);
		var ind = checkIfIn(item, vm.ceshItems);
		if(ind > -1) {
			vm.ceshItems.splice(ind, 1);
			vm.categoriesSelected -= 1;
		} else {
			vm.ceshItems.push(item);
			vm.categoriesSelected += 1;
		}
		for (var i = 0, length = vm.categorieList.length; i < length; i++) {
			if (item.categoryId === vm.categorieList[i].id) {
				if(ind > -1) {
					vm.categorieList[i].selectedCount -= 1;
				} else {
					vm.categorieList[i].selectedCount += 1;
				}
				break;
			}
		}
	}

	function itemText(count){
		// vm.itemText = '';
		var listLength = count;
		if (listLength === 1) {
			return [translate('ITEMS_DECLINE.ONE_ITEM'),
				translate('ITEM_SELECTED', {number: count, items: translate("ITEMS_DECLINE.ONE_ITEM")})];
		} else if((listLength >= 5 && listLength <=20) || (listLength % 10 > 4 && listLength % 10 < 10) || listLength % 10 === 0) {
			return [translate('ITEMS_DECLINE.ITEMS_5_TO_10'),
				translate('ITEMS_SELECTED', {number: count, items: translate('ITEMS_DECLINE.ITEMS_5_TO_10')}),
				'DINING_OPTIONS_DECLINE.OPTIONS_5_10'];
		} else if (listLength % 10 > 1 && listLength % 10 <5){
			return [translate('ITEMS_DECLINE.ITEMS_2_TO_4'),
				translate('ITEMS_SELECTED', {number: count, items: translate('ITEMS_DECLINE.ITEMS_2_TO_4')}),
				'DINING_OPTIONS_DECLINE.OPTIONS_2_4'];
		} else if (listLength % 10 === 1) {
			return [translate('ITEMS_DECLINE.ITEMS_1'),
				translate('ITEM_SELECTED', {number: count, items: translate("ITEMS_DECLINE.ITEMS_1")})];
		}
	}

	function backToList() {
		$state.go('settings.tax', $stateParams, {
			reload: true
		});
	}

	$scope.clearRate = function() {
		$scope.models.value = null;
		$scope.emptyValue = null;
		$scope.options.selectType = "INCLUDED";
	}

	$scope.clearCategoryAndReason = function () {
		$scope.options.selectVatCategory = 'ZERO_RATED_GOODS';
		$scope.options.selectVatExemptReason = null;
		$scope.options.vatExemptReasonText = null;
	}

	$scope.clearReason = function() {
		$scope.options.selectVatExemptReason = null;
		$scope.options.vatExemptReasonText = null;
	}
}

export default {
	type: 'controller',
	name: 'createtaxCtrl',
	value: createtaxCtrl
};