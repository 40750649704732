import reportTemplate from './templates-pug/navigator/report.pug';
import dialogBaseTemplate from './templates-pug/dialog/dialog-base.pug';
import dialogEditAverageCostTemplate from './templates-pug/dialog/dialog-edit-average-cost.pug';
import importDialogListImportedDataUpdateTemplate from './templates-pug/import-entity/include/dialog-list-imported-data-update.pug';
import importDialogListImportedDataUpdateErrorTemplate from './templates-pug/import-entity/include/dialog-list-imported-data-update-error.pug';
import dialogErrorNetworkConnectionTemplate from './templates-pug/dialog/dialog-error-network-connection.pug'
import dialogRequestErrorTemplate from './templates-pug/dialog/dialog-request-error.pug';
import dialogListGodsUpdateTemplate from './templates-pug/dialog/dialog-list-gods-update.pug';
import dialogListGodsUpdateErrorTemplate from './templates-pug/dialog/dialog-list-gods-update-error.pug';
import billingDialogBillingBaseTemplate from './templates-pug/dialog/billing/dialog-billing-base.pug';
import dialogBillingGatewayRejectedTemplate from './templates-pug/dialog/billing/dialog-billing-gateway-rejected.pug';
import dialogBillingEstimatedInvoiceTemplate from './templates-pug/dialog/billing/dialog-billing-estimated-invoice.pug';
import dialogLiveChatNotAvailableTemplate from './templates-pug/dialog/dialog-live-chat-not-available.pug';
import dialogCancelReceiptTemplate from './templates-pug/dialog/dialog-cancel-receipt.pug';
import dialogEnableInvoicingTemplate from './templates-pug/dialog/dialog-enable-invoicing.pug';
import dialogAfterRegistrationTemplate from './templates-pug/dialog/dialog-after-registration.pug';
import dialogBillingSubscribeTemplate from './templates-pug/dialog/billing/dialog-billing-subscribe.pug';
import dialogBillingSubscribeActivateTemplate from './templates-pug/dialog/billing/dialog-billing-subscribe-activate.pug';
import importDownloadStartTemplate from './templates-pug/import-entity/include/download-start.pug';
import importOnAttachTemplate from './templates-pug/import-entity/include/on-attach.pug';
import importDownloadErrorTemplate from './templates-pug/import-entity/include/download-error.pug';
import importFormatErrorTemplate from './templates-pug/import-entity/include/format-error.pug';
import importPurchaseSuccess from './templates-pug/inventory/purchase/include/parser-success.pug';
import importPurchaseError from './templates-pug/inventory/purchase/include/parser-error.pug';
import importTransferSuccess from './templates-pug/inventory/transfer/include/parser-success.pug';
import importTransferError from './templates-pug/inventory/transfer/include/parser-error.pug';
import importPreloaderTemplate from './templates-pug/import-entity/include/preloader.pug';
import importConfirmPreloaderTemplate from './templates-pug/import-entity/include/confirm-preloader.pug';
import parserSuccessTemplate from './templates-pug/clients/include/parser-success.pug';
import parserErrorTemplate from './templates-pug/clients/include/parser-error.pug';
import importBadEncodingTemplate from './templates-pug/import-entity/include/bad-encoding.pug';
import importHeadStartTemplate from './templates-pug/import-entity/include/head-start.pug';
import importHeadSuccessTemplate from './templates-pug/import-entity/include/head-success.pug';
import importHeadParserErrorTemplate from './templates-pug/import-entity/include/head-parser-error.pug';
import adjustPointsDialogTemplate from './templates-pug/dialog/adjust-points-dialog.pug';
import dialogBillingPaymentWhenCreateTemplate from './templates-pug/dialog/billing/dialog-billing-payment-when-create.pug';
import dialogPinTemplate from './templates-pug/dialog/dialog-pin.pug';
import dialogVariationsAddOptionsTemplate from './templates-pug/dialog/dialog-variations-add-options.pug';
import dialogVariationAddVariantTemplate from './templates-pug/dialog/dialog-variations-addvariant.pug';
import dialogVariationsEditVariantTemplate from './templates-pug/dialog/dialog-variations-editvariant.pug';
import createEditVariantsTemplate from './templates-pug/goods/create-edit-variants.pug';
import goodsDownloadStartTemplate from './templates-pug/goods/include/download-start.pug';
import goodsOnAttachTemplate from './templates-pug/goods/include/on-attach.pug';
import goodsDownloadErrorTemplate from './templates-pug/goods/include/download-error.pug';
import goodsFormatErrorTemplate from './templates-pug/goods/include/format-error.pug';
import goodsShortEntryTemplate from './templates-pug/goods/include/short-entry.pug';
import goodsLimit10000Template from './templates-pug/goods/include/limit-10000.pug';
import goodsMaxFileSizeTemplate from './templates-pug/goods/include/max-file-size.pug';
import goodsPreloaderTemplate from './templates-pug/goods/include/preloader.pug';
import goodsParserSuccessTemplate from './templates-pug/goods/include/parser-success.pug';
import goodsParserErrorTemplate from './templates-pug/goods/include/parser-error.pug';
import goodsDownloadWrongHeaderTemplate from './templates-pug/goods/include/download-wrong-header.pug';
import goodsDownloadLimit200VariantsTemplate from './templates-pug/goods/include/download-limit-200-variants.pug';
import goodsWrongTaxListTemplate from './templates-pug/goods/include/wrong-tax-list.pug';
import goodsBadEncodingTemplate from './templates-pug/goods/include/bad-encoding.pug';
import goodsHeadStartTemplate from './templates-pug/goods/include/head-start.pug';
import goodsHeadSuccessTemplate from './templates-pug/goods/include/head-success.pug';
import goodsHeadParserErrorTemplate from './templates-pug/goods/include/head-parser-error.pug';
import dialogCountSaveTemplate from './templates-pug/dialog/inventory/dialog-count-save.pug';
import dialogAddSupplierTemplate from './templates-pug/dialog/dialog-add-supplier.pug';
import dialogAddByTemplate from './templates-pug/dialog/inventory/dialog-add-by.pug';
import orderReceivingMesageTemplate from './templates-pug/dialog/order-receiving-mesage.pug';
import sendPurchaseOrderTemplate from './templates-pug/dialog/send-purchase-order.pug';
import enterPasswordTemplate from './templates-pug/dialog/my-account/enter-password.pug';
import dialogChangeEmailTemplate from './templates-pug/dialog/my-account/dialog-change-email.pug';
import dialogChangePasswordTemplate from './templates-pug/dialog/my-account/dialog-change-password.pug';
import dialogReasonForDeletionTemplate from './templates-pug/dialog/my-account/dialog-reason-for-deletion.pug';
import dialogDeleteAccConfirmTemplate from './templates-pug/dialog/my-account/dialog-delete-acc-confirm.pug';
import dialogRecaptchaTemplate from './templates-pug/dialog/dialog-recaptcha.pug';
import dialogDelCashRegisterTemplate from './templates-pug/dialog/dialog-del-cash-register.pug';
import dialogBaseMultilineTemplate from './templates-pug/dialog/dialog-base-multiline.pug';
import selectDiningOptionsTemplate from './templates-pug/dialog/tax-depend-on-dining/select-dining-options.pug';
import dialogSelectCategorysTemplate from './templates-pug/dialog/tax-depend-on-dining/dialog-select-categorys.pug';
import commentDialogTemplate from './templates-pug/for-directive/comment-dialog.pug';
import lvSearchHideGoodsTemplate from './templates-pug/lv-search/lv-search-hide-goods.pug';
import calendarViewTemplate from '../modules/lv-calendar/templates-pug/calendar-view.pug';
import containerMonthsTemplate from '../modules/lv-calendar/templates-pug/container-months.pug';
import datePickerMonthsContainerTemplate from '../modules/lv-date-picker/templates-pug/date-picker-months-container.pug';
import datePickerViewTemplate from '../modules/lv-date-picker/templates-pug/date-picker-view.pug';
import dialogInsertLinkTemplate from './templates-pug/dialog/dialog-insert-link.pug';
import dialogBillingEstimatedListTemplate from './templates-pug/dialog/billing/dialog-billing-estimated-list.pug';
import dialogCreateCategory from './templates-pug/dialog/dialog-create-category.pug';
import dialogFeatureSettingsTemplate from './templates-pug/dialog/dialog-feature-settings.pug';
import dialogCreateTax from './templates-pug/dialog/dialog-create-tax.pug';


// TODO: Oleksii: no template (file: create-outlet-ctrl.js)
// './templates-pug/dialog/dialog-billing-base.pug';
// Replaced with './templates-pug/dialog/billing/dialog-billing-base.pug'

run.$inject = ['$templateCache'];
function run($templateCache) {
    $templateCache.put('module/lv-calendar/templates/container-months.html', containerMonthsTemplate);
    $templateCache.put('module/lv-calendar/templates/calendar-view.html', calendarViewTemplate);
    $templateCache.put('modules/lv-date-picker/templates-pug/date-picker-months-container.html', datePickerMonthsContainerTemplate);
    $templateCache.put('module/lv-date-picker/templates/date-picker-view.html', datePickerViewTemplate);
    $templateCache.put('dashboard-page/templates/lv-search/lv-search-hide-goods.html', lvSearchHideGoodsTemplate);
    $templateCache.put('dashboard-page/templates/for-directive/comment-dialog.html', commentDialogTemplate);
    $templateCache.put('dashboard-page/templates/dialog/tax-depend-on-dining/dialog-select-categorys.html', dialogSelectCategorysTemplate);
    $templateCache.put('dashboard-page/templates/dialog/tax-depend-on-dining/select-dining-options.html', selectDiningOptionsTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-base-multiline.html', dialogBaseMultilineTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-del-cash-register.html', dialogDelCashRegisterTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-enable-invoicing.html', dialogEnableInvoicingTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-recaptcha.html', dialogRecaptchaTemplate);
    $templateCache.put('dashboard-page/templates/dialog/my-account/dialog-delete-acc-confirm.html', dialogDeleteAccConfirmTemplate);
    $templateCache.put('dashboard-page/templates/dialog/my-account/dialog-reason-for-deletion.html', dialogReasonForDeletionTemplate);
    $templateCache.put('dashboard-page/templates/dialog/my-account/dialog-change-password.html', dialogChangePasswordTemplate);
    $templateCache.put('dashboard-page/templates/dialog/my-account/dialog-change-email.html', dialogChangeEmailTemplate);
    $templateCache.put('dashboard-page/templates/dialog/my-account/enter-password.html', enterPasswordTemplate);
    $templateCache.put('dashboard-page/templates/dialog/send-purchase-order.html', sendPurchaseOrderTemplate);
    $templateCache.put('dashboard-page/templates/dialog/order-receiving-mesage.html', orderReceivingMesageTemplate);
    $templateCache.put('dashboard-page/templates/dialog/inventory/dialog-add-by.html', dialogAddByTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-add-supplier.html', dialogAddSupplierTemplate);
    $templateCache.put('dashboard-page/templates/dialog/inventory/dialog-count-save.html', dialogCountSaveTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/head-parser-error.html', goodsHeadParserErrorTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/head-success.html', goodsHeadSuccessTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/head-start.html', goodsHeadStartTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/bad-encoding.html', goodsBadEncodingTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/wrong-tax-list.html', goodsWrongTaxListTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/download-limit-200-variants.html', goodsDownloadLimit200VariantsTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/download-wrong-header.html', goodsDownloadWrongHeaderTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/parser-error.html', goodsParserErrorTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/parser-success.html', goodsParserSuccessTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/preloader.html', goodsPreloaderTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/max-file-size.html', goodsMaxFileSizeTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/limit-10000.html', goodsLimit10000Template);
    $templateCache.put('dashboard-page/templates/goods/include/short-entry.html', goodsShortEntryTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/format-error.html', goodsFormatErrorTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/download-error.html', goodsDownloadErrorTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/on-attach.html', goodsOnAttachTemplate);
    $templateCache.put('dashboard-page/templates/goods/include/download-start.html', goodsDownloadStartTemplate);
    $templateCache.put('dashboard-page/templates/goods/create-edit-variants.html', createEditVariantsTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-variations-editvariant.html', dialogVariationsEditVariantTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-variations-addvariant.html', dialogVariationAddVariantTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-variations-add-options.html', dialogVariationsAddOptionsTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-pin.html', dialogPinTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-payment-when-create.html', dialogBillingPaymentWhenCreateTemplate);
    $templateCache.put('dashboard-page/templates/dialog/adjust-points-dialog.html', adjustPointsDialogTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/head-parser-error.html', importHeadParserErrorTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/head-success.html', importHeadSuccessTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/head-start.html', importHeadStartTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/bad-encoding.html', importBadEncodingTemplate);
    $templateCache.put('dashboard-page/templates/clients/include/parser-error.html', parserErrorTemplate);
    $templateCache.put('dashboard-page/templates/clients/include/parser-success.html', parserSuccessTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/preloader.html', importPreloaderTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/confirm-preloader.html', importConfirmPreloaderTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/format-error.html', importFormatErrorTemplate);
    $templateCache.put('dashboard-page/templates/inventory/purchase/include/parser-success.html', importPurchaseSuccess);
    $templateCache.put('dashboard-page/templates/inventory/purchase/include/parser-error.html', importPurchaseError);
    $templateCache.put('dashboard-page/templates/inventory/transfer/include/parser-success.html', importTransferSuccess);
    $templateCache.put('dashboard-page/templates/inventory/transfer/include/parser-error.html', importTransferError);
    $templateCache.put('dashboard-page/templates/import-entity/include/download-error.html', importDownloadErrorTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/on-attach.html', importOnAttachTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/download-start.html', importDownloadStartTemplate);
    $templateCache.put('dashboard-page/templates/navigator/report.html', reportTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-base.html', dialogBaseTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-edit-average-cost.html', dialogEditAverageCostTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/dialog-list-imported-data-update.html', importDialogListImportedDataUpdateTemplate);
    $templateCache.put('dashboard-page/templates/import-entity/include/dialog-list-imported-data-update-error.html', importDialogListImportedDataUpdateErrorTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-error-network-connection.html', dialogErrorNetworkConnectionTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-request-error.html', dialogRequestErrorTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-list-gods-update.html', dialogListGodsUpdateTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-list-gods-update-error.html', dialogListGodsUpdateErrorTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-base.html', billingDialogBillingBaseTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-gateway-rejected.html', dialogBillingGatewayRejectedTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-estimated-invoice.html', dialogBillingEstimatedInvoiceTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-live-chat-not-available.html', dialogLiveChatNotAvailableTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-cancel-receipt.html', dialogCancelReceiptTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-after-registration.html', dialogAfterRegistrationTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-subscribe.html', dialogBillingSubscribeTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html', dialogBillingSubscribeActivateTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-insert-link.html', dialogInsertLinkTemplate);
    $templateCache.put('dashboard-page/templates/dialog/billing/dialog-billing-estimated-list.html', dialogBillingEstimatedListTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-create-category.html', dialogCreateCategory);
    $templateCache.put('dashboard-page/templates/dialog/dialog-feature-settings.html', dialogFeatureSettingsTemplate);
    $templateCache.put('dashboard-page/templates/dialog/dialog-create-tax.html', dialogCreateTax);
}

export default run;
