import angular from 'angular';

createClass.$inject = [];

function createClass() {
    return class PermitsManager {
        constructor(permitsList) {
            this.__permitsList = angular.isArray(permitsList) ? permitsList : [];
        }

        get costAllowed() {
            return this.__permitsList.includes('ACCESS_VIEW_COST');
        }

        get hasBillingAccess() {
            return this.__permitsList.includes('ACCESS_BILLING');
        }

        get canCancelReceipts() {
            return this.__permitsList.includes('ACCESS_CANCEL_RECEIPTS');
        }

        get hasInventoryAccess() {
            return this.__permitsList.includes('ACCESS_INVENTORY');
        }
        get hasTaxesAccess() {
            return this.__permitsList.includes('ACCESS_TAXES');
        }
    }
}

export default {
    type: 'factory',
    name: 'PermitsManager',
    value: createClass
};
