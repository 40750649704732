import template from '../templates-pug/for-directive/select-list-button.pug';

directiveSelectListButton.$inject = [];
function directiveSelectListButton() {
    return {
        restrict: 'E',
        template: template,
        controller: selectListButtonController,
        controllerAs: 'ctrl',
        bindToController: true,
        scope: {
            buttonText: '<',
            buttonId: '<',
            buttonFn: '&buttonFn'
        }
    }
}

selectListButtonController.$inject = [];
function selectListButtonController() {

    const self = this;

    self.onButtonClick = () => {
        this.buttonFn();
    }
}

export default {
    type: 'directive',
    name: 'selectListButton',
    value: directiveSelectListButton
}
