/**
 * Created by mars on 12/13/15.
 */

import angular from 'angular'

goodsListControl.$inject = [
    'factoryGoods',
    '$state',
    '$stateParams',
    'serviceToast',
    'serviceDialog',
    '$filter',
    '$wares',
    '$categories',
    'factoryPermits',
    '$shortOutlets',
    '$scope',
    '$rootScope',
    '$cookies',
    '$currencyOptionsEdit',
    '$currencyOptionsShow',
    '$countOptionsShow',
    '$countOptionsEdit',
    '$percentInfinityToHundred',
    '$profile',
    'siteLangCode',
    '$controller',
    'shareScope',
    '$timeout',
    '$translate',
    'lvMixpanel',
    '$critCountOptionsEdit',
    '$quantOptionsEdit',
    '$countOptionsEditDivisible',
    '$permitsManager',
    'dialogs'
];

function goodsListControl(factoryGoods,
                          $state,
                          $stateParams,
                          serviceToast,
                          serviceDialog,
                          $filter,
                          $wares,
                          $categories,
                          factoryPermits,
                          $shortOutlets,
                          $scope,
                          $rootScope,
                          $cookies,
                          $currencyOptionsEdit,
                          $currencyOptionsShow,
                          $countOptionsShow,
                          $countOptionsEdit,
                          $percentInfinityToHundred,
                          $profile,
                          siteLangCode,
                          $controller,
                          shareScope,
                          $timeout,
                          $translate,
                          lvMixpanel,
                          $critCountOptionsEdit,
                          $quantOptionsEdit,
                          $countOptionsEditDivisible,
                          $permitsManager,
                          dialogs
) {
    var vm = this,
        translate = $filter('translate');

    vm.displayCost = $permitsManager.costAllowed;

    vm.categories = angular.copy($categories);
    vm.categories.unshift({id: null, name: $translate.instant('WITHOUT_CATEGORY')});

    vm.buttonStates = {
        del: {
            disabled: false
        }
    };

    vm.pages = factoryGoods.pager.pages;
    vm.stateP = $stateParams;
    vm.stateP.outletId = (!vm.stateP.outletId || vm.stateP.outletId === 'null') ? null : vm.stateP.outletId;
    vm.stateP.offset = vm.stateP.page * vm.stateP.limit;

    //Task # 19957 Change stubs on Advanced inventory screens
    // vm.plugsFilters = !!(!$scope.selectReason && !$scope.selectOutlet.id);
    vm.plugsSearch = !!$stateParams.search;
    // console.log("$state = ", $state);

    /*** формат ввода валюты ***/
    vm.currencyOptionsEdit = $currencyOptionsEdit;

    /*** формат отображения валюты ***/
    vm.currencyOptionsShow = $currencyOptionsShow;
    // console.log("goodsListControl currencyOptionsShow = ", vm.currencyOptionsShow);

    /*** формат ввода колличества ***/
    vm.countOptionsEdit = $countOptionsEdit;

    /*** формат отображения колличества ***/
    vm.countOptionsShow = $countOptionsShow;

    /*** формат отображения процентов ***/
    vm.percentInfinityToHundred = $percentInfinityToHundred;

    // читаем колличество знаков после запятой в валюте
    vm.numberOfSigns = $currencyOptionsEdit.decimalPlacesOverride;



    vm.wares = $wares.wares.map(item => {
        item.variants = [];
        if (item.useProduction) {
            item.keepCount = true;
        }
        return item
    });

    //  EMPTY STATE
    vm.emptyState = vm.wares.length === 0 && (vm.stateP.inventory === "all" || !vm.stateP.inventory)
        && (vm.stateP.category === "all" || !vm.stateP.category)
        && (vm.stateP.outletId === null || vm.stateP.outletId === 'all')
        && $stateParams.search === undefined;
    //  END EMPTY STATE


    vm.shortOutlets = $shortOutlets;
    // еще одна переменная для списка магазинов включена для того, чтобы инжектился контроллер createEditVariantControl
    $scope.outlets = $shortOutlets;
    $scope.profile = angular.copy($profile);
    vm.inventoryEnabled = $profile.inventoryEnabled;

    vm.wares.forEach(function(val) {
        if (val.hasVariants === true && !val.expanded && val.expanded !== true) {
            val.expanded = false;
        }
    });

    $scope.isSingleShop = vm.shortOutlets.length < 2 || (vm.stateP.outletId && vm.stateP.outletId !== 'null' && vm.stateP.outletId !== 'all');

    const setPriceForOneShop = () => {
        if ($scope.isSingleShop) {
            angular.forEach(vm.wares, (ware) => {
                ware.priceSingle = ware.singleShopData.price;
            })
        }
    }

    setPriceForOneShop();

    vm.showBtnDel = false;
    vm.checkerAll = false;

    vm.searchFunction = searchFunction;
    // TODO: remove after lv-select complete
    vm.categoryOpen = categoryOpen;

    vm.onAdd = onAdd;
    vm.onDel = onDel;
    vm.selectWare = selectWare;
    vm.selectAll = selectAll;

    vm.behaveWithVariants = behaveWithVariants;
    vm.updatingDataAfterEditingAnOption = updatingDataAfterEditingAnOption;

    $scope.editVariantFromList = function(variant) {

        $scope.openDialog = true;
        factoryGoods.getWare(variant.wareId).then(function(data) {
            if (data.result !== 'ok') {} else {
                $scope.ware = data;
                $controller('createEditVariantControl', {
                    $scope: $scope,
                    $currencyOptionsEdit: $currencyOptionsEdit,
                    $countOptionsEdit: $countOptionsEdit,
                    $critCountOptionsEdit: $critCountOptionsEdit,
                    $quantOptionsEdit: $quantOptionsEdit,
                    $countOptionsEditDivisible: $countOptionsEditDivisible,
                    $permitsManager: $permitsManager});
                var editedVatiant;
                for (var i = 0; i < $scope.ware.variations.variants.length; i++) {
                    if ($scope.ware.variations.variants[i].id === variant.variationId) {
                        editedVatiant = $scope.ware.variations.variants[i];
                    }
                }
                if ($scope.ware.imgUrl) {
                    $scope.ware._radio = 'img';
                } else {
                    $scope.ware._radio = 'color';
                }

                editedVatiant.wareId = variant.wareId;
                editedVatiant.oldPrice = editedVatiant.salePrice;
                var index = "";
                $scope.ware.variations.variants.forEach(function(v, i) {
                    if (v.id === variant.variationId) {
                        index = i;
                    }
                });
                $scope.editVariant(editedVatiant, index);

            }
        });
        // console.log($scope);
    };

    //this function is used by lv-search directive
    function searchFunction(val) {
        // console.log("$scope.stateFromList = ", $scope.stateFromList);
        // console.log("$scope.checkFromList = ", $scope.checkFromList);
        $state.go($state.$current.name, {
            page: 0,
            limit: $stateParams.limit,
            search: val,
            outletId: $stateParams.outletId
        }, { reload: true });
    }





    /**
     * @ngdoc function
     * @param {Object} ware
     */
    function categoryOpen(ware) {
        ware._categories = $categories;
    }

    // rename it
    function isEmptyForDel() {
        for (var i = 0; i < vm.wares.length; i++) {
            if (vm.wares[i]._select) {
                return false;
            }
        }
        return true;
    }

    function behaveWithVariants(ware) {
        if (ware && ware.id) {
            if (ware.expanded === false) {
                ware.expanded = true;
                factoryGoods.getVariantsByWareId({
                    wareId: ware.id,
                    outletId: ($scope.stateFromList.outletId !== 'null' && $scope.stateFromList.outletId !== undefined && $scope.stateFromList.outletId !== 'all') ? $scope.stateFromList.outletId : undefined
                }).then(function(data) {
                    ware.variants = data;
                    return ware.variants;
                });
            } else {
                ware.expanded = false;
            }
        }
    }

    function updatingDataAfterEditingAnOption(variant) {
        factoryGoods
            .getWares(vm.stateP)
            .then(function(data) {
                vm.wares = angular.copy(data.wares);
                setPriceForOneShop();
                factoryGoods.getVariantsByWareId({
                    wareId: variant.wareId,
                    outletId: ($scope.stateFromList.outletId !== 'null' && $scope.stateFromList.outletId !== undefined && $scope.stateFromList.outletId !== 'all') ? $scope.stateFromList.outletId : undefined
                }).then(function(data) {
                    // console.log(data);
                    vm.wares.forEach(function(ware, index) {
                        categoryOpen(ware);
                        if (ware.id === variant.wareId) {
                            vm.wares[index].variants = data;
                            vm.wares[index].expanded = true;
                        }
                    });
                });
            });
    }


    // данные для сохранение состояния фильтров и ЧЕКБОКСОВ
    $scope.editTableState = 'goods.edit';
    $scope.stateFromList = $state.params;
    $scope.checkFromList = $stateParams.checkFromList;


    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ СКИДОК ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    if (vm.wares.length == 0 && $scope.stateFromList.page > 0) {
        $state.go('goods.price', {
            page: vm.pages - 1,
            outletId: $scope.stateFromList.outletId,
            category: $scope.stateFromList.category,
            inventory: $scope.stateFromList.inventory
        });
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ СКИДОК ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


    if ($scope.checkFromList == undefined || $scope.checkFromList.length == 0) {
        for (var i = 0; i < vm.wares.length; i++) {
            vm.wares[i]._select = false;
        }
    } else {
        for (var i = 0; i < $scope.checkFromList.length; i++) {
            for (var q = 0; q < vm.wares.length; q++) {
                ($scope.checkFromList[i] == vm.wares[q].id) ? vm.wares[q]._select = true: "";
                (!vm.wares[q]._select) ? vm.wares[q]._select = false: "";
            }
        }
    }


    // данные для сохранение состояния фильтров, "развернутых" товаров до вариантов и ЧЕКБОКСОВ



    // КОД для сохранения массива ID выбранных СКИДОК
    $scope.checkWaresList = [];
    $scope.$watch('vmGoodsListCtrl.wares', function(newval, oldval) {
        for (var k = 0; k < newval.length; k++) {
            var indexChecked = $scope.checkWaresList.indexOf(newval[k].id);
            saveWaresCheckAndExpandedAttr(newval[k], newval[k]._select, indexChecked, $scope.checkWaresList);
        }

        function saveWaresCheckAndExpandedAttr(ware, wareProperty, index, array) {
            if (wareProperty == true) {
                if (index > -1) {
                    array.splice(index, 1);
                }
                array.push(ware.id);
            } else {
                if (index > -1) {
                    array.splice(index, 1);
                }
            }
        }
        ($scope.checkWaresList.length == 0) ? vm.showBtnDel = false: vm.showBtnDel = true;
        ($scope.checkWaresList.length == vm.wares.length) ? vm.checkerAll = true: vm.checkerAll = false;

        // создаем переменные в куках с данными из этого контроллера, для корректной работы рефреша браузера
        $cookies.putObject('checkWaresList', $scope.checkWaresList);
    }, true);
    // КОД для сохранения массива ID выбранных СКИДОК


    function onAdd() {
        $state.go('goods.create', {
            stateToSave: $scope.stateFromList,
            checkToSave: $scope.checkWaresList,
        });
    }

    //checks goods or goods removed to display the correct text in the pop-up
    function deleteItemsMassage() {
        var i = 0,
            count = 0;
        do {
            if (vm.wares[i]._select) {
                count++;
            }
        } while (++i < vm.wares.length);
        if (1 < count) {
            return [
                translate('DEL_WARES'),
                translate('CONFIRM_DEL_WARES'),
                'WARES_DEL_SUCCESS'
            ];
        } else {
            return [
                translate('DEL_WARE'),
                translate('CONFIRM_DEL_WARE'),
                'WARE_DEL_SUCCESS'
            ];
        }
    }
    //end checks goods or goods removed to display the correct text in the pop-up

    function onDel() {
        var deleteMes = deleteItemsMassage();

        if (!factoryPermits.editProfile) {
            serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
            return;
        }

        if (isEmptyForDel()) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('ATTENTION'),
                content: translate('EMPTY_WARES_FOR_DEL'),
                buttons: [{
                    class: 'primary',
                    text: 'ON_OK',
                    action: function() {

                    }
                }]
            });
        } else {

            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: deleteMes[0],
                content: deleteMes[1],
                buttons: [{
                    text: 'ON_CANCEL'
                },
                    {
                        class: 'primary l-margin-left-8-i',
                        text: 'ON_DEL',
                        action: function() {
                            vm.buttonStates.del.disabled = true;
                            factoryGoods
                                .deleteWares(vm.wares)
                                .then(res => {
                                    if(res.result === 'error'){
                                        if(res.message === 'item_deleted') {
                                            dialogs.listItemDeletedEarlier()
                                                .then(() => refreshList())
                                                .finally(() => {
                                                    vm.buttonStates.del.disabled = false;
                                                });
                                        }
                                    } else {
                                        serviceToast.show(deleteMes[2], 'success');
                                        refreshList().finally(() => {
                                            vm.buttonStates.del.disabled = false;
                                        });
                                    }
                                },
                                function(err) {
                                    vm.buttonStates.del.disabled = false;
                                    vm.selectWare();
                                    switch (err.result) {
                                        case 'empty_del':
                                            console.log('empty_del');
                                            break;
                                        default:
                                            serviceToast.show('ERROR_DEL_WARE', 'error');
                                    }
                                }
                            );
                        }
                    }
                ]
            });

            function refreshList() {
                vm.selectWare();
                return $state.go($state.$current.name, {
                    page: $scope.stateFromList.page,
                    limit: $scope.stateFromList.limit,
                    outletId: $scope.stateFromList.outletId,
                    category: $scope.stateFromList.category,
                    inventory: $scope.stateFromList.inventory,
                    checkFromList: []
                }, { reload: true });
            }
        }
    }

    function selectWare() {
        vm.showBtnDel = !isEmptyForDel();
    }



    function selectAll() {
        var i = vm.wares.length;
        //permission to edit profile, false for manager
        if (!factoryPermits.editProfile) {
            return;
        }
        if (vm.checkerAll) {
            vm.checkerAll = false;
            while (0 < i--) {
                vm.wares[i]._select = false;
            }
        } else {
            vm.checkerAll = true;
            while (0 < i--) {
                vm.wares[i]._select = true;
            }
        }
        vm.selectWare();
    }


    // item inline editing
    const editWare = ware => {
        if (!factoryPermits.editProfile) {
            serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
        } else {
            factoryGoods
              .editWareInline(ware)
              .then(res => {
                  if (res.result === "ok") {
                      serviceToast.show('EDIT_WARE_SUCCESS', 'success');
                      if (ware.hasOwnProperty("wareCategoryId") && Number.isInteger(parseInt(vm.stateP.category))) {
                          factoryGoods.getWares(vm.stateP).then(data => {
                              vm.wares = angular.copy(data.wares);
                              setPriceForOneShop();
                          });
                      } else {
                          const index = vm.wares.findIndex(item => item.id === ware.id);
                          vm.wares = vm.wares.map((item, i) => {
                              if (i === index) {
                                  const newItem = res.ware;
                                  newItem.priceSingle = newItem.singleShopData.price;
                                  return newItem;
                              }
                              return item;
                          })
                      }
                  } else {
                      serviceToast.show('ERR_EDIT_WARE', 'error');
                  }
              });
        }
    }
    // item inline editing


    const getOutletId = (stateParams, shortOutlets) => {
        if (Number.isInteger(parseInt(stateParams.outletId))) {
            return stateParams.outletId;
        }
        if (shortOutlets.length === 1) {
            return shortOutlets[0].id;
        }
        return null;
    }

    vm.editItemPrice = ware => {
        const getFreePrice = ware => {
            if (ware.hasOwnProperty("priceSingle")) {
                return ware.priceSingle === "" || ware.priceSingle === null;
            }
            return ware.price === "" || ware.price === null;
        };

        const freePrice = getFreePrice(ware);
        const salePrice = ware.priceSingle ? ware.priceSingle : ware.price;

        const editItemData = {
            id: ware.id,
            outletId: getOutletId($stateParams, $shortOutlets),
            salePrice: freePrice ? 0 : salePrice,
            freePrice
        };

        editWare(editItemData);
    }

    vm.editItemPrimeCost = ware => {
        const editItemPrimeCostData = {
            id: ware.id,
            outletId: getOutletId($stateParams, $shortOutlets),
            primeCost: ware.primeCost
        };

        editWare(editItemPrimeCostData);
    }

    vm.editItemCategory = ware => {
        const editItemCategoryData = {
            id: ware.id,
            outletId: getOutletId($stateParams, $shortOutlets),
            wareCategoryId: ware.categoryId
        }

        editWare(editItemCategoryData);
    }

    vm.editItemStock = ware => {
        const editItemStockData = {
            id: ware.id,
            outletId: getOutletId($stateParams, $shortOutlets),
            count: ware.count
        }

        editWare(editItemStockData);
    }


    // variant inline editing
    const editVariant = variant => {
        if (!factoryPermits.editProfile) {
            serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
            return;
        }

        factoryGoods
            .editVariantInline(variant)
            .then(d => {
                switch (d.result) {
                    case "ok":
                        serviceToast.show('EDIT_VARIANT_SUCCESS', 'success');
                        break;
                    default:
                        serviceToast.show('ERR_EDIT_VARIANT', 'error');
                }
            })
            .then(() => {
                if (variant.hasOwnProperty("count")) {
                    factoryGoods
                      .getWares(vm.stateP)
                      .then(data => {
                        vm.wares = angular.copy(data.wares);
                        setPriceForOneShop();
                        factoryGoods.getVariantsByWareId({
                            wareId: variant.wareId,
                            outletId: getOutletId($stateParams, $shortOutlets)
                        })
                      .then(data => {
                        const item = vm.wares.find(ware => ware.id === variant.wareId);

                        item.variants = data;
                        item.expanded = true;
                      });
                  });
                } else {
                    factoryGoods
                      .getVariantsByWareId({
                        wareId: variant.wareId,
                        outletId: getOutletId($stateParams, $shortOutlets)
                      }).then(data => {
                        const item = vm.wares.find(item => item.id === variant.wareId);
                        item.variants = angular.copy(data);
                      });
                }
            })

    }
    // variant inline editing

    vm.editVariantPrice = variant => {
        const editVariantPriceData = {
            wareId: variant.wareId,
            variationId: variant.variationId,
            outletId: getOutletId($stateParams, $shortOutlets),
            freePrice: variant.salePrice === "" || variant.salePrice === null,
            salePrice: this.freePrice ? 0 : variant.salePrice,
        };

        editVariant(editVariantPriceData);
    }

    vm.editVariantPrimeCost = variant => {
        const editVariantPrimeCostData = {
            wareId: variant.wareId,
            variationId: variant.variationId,
            outletId: getOutletId($stateParams, $shortOutlets),
            primeCost: variant.primeCost
        };

        editVariant(editVariantPrimeCostData);
    }

    vm.editVariantStock = variant => {
        const editVariantStockData = {
            wareId: variant.wareId,
            variationId: variant.variationId,
            outletId: getOutletId($stateParams, $shortOutlets),
            count: variant.count
        };

        editVariant(editVariantStockData);
    }


    //-- Task #25633 Update stubs for empty states
    emptyStateLink();
    function emptyStateLink() {
        let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
        // url lang where we have info
        let urlLang = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "it", "pl", "gr", "zh"];
        //emptyStateCategory Learn more
        $scope.emptyStateLearnMoreItems = urlLang.indexOf(siteUrlLang) !== -1
            ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-add-items-loyverse-back-office?utm_source=Back%20Office&utm_medium=Items"
            : "https://help.loyverse.com/help/how-add-items-loyverse-back-office?utm_source=Back%20Office&utm_medium=Items";
    }
    //-- END Task #25633 Update stubs for empty states
    //-- Task #25853 Add events to Mixpanel for transitions to help
    $scope.trackItemsHelp = function () {
        lvMixpanel.track('Help', {"Property": "Items"});
    };
    //-- END Task #25853 Add events to Mixpanel for transitions to help

    shareScope.prepForBroadcast($scope);

    return this;
}

export default {
    type: 'controller',
    name: 'goodsListControl',
    value: goodsListControl
};
