import angular from 'angular';
import template from '../../templates-pug/select/select.pug';

selectDirective.$inject = [];
function selectDirective() {
    return {
        restrict: 'E',
        template: template,
        controller: SelectController,
        controllerAs: 'ctrl',
        bindToController: true,
        require: {
            ngModel: 'ngModel',
            inputContainerAdapter: '?lvInputContainerAdapter'
        },
        scope: {
            items: '<lvItems',
            viewProperty: '@lvViewProperty',
            valueProperty: '@lvValueProperty',
            maxVisibleItems: '<?maxVisibleItems',
            emptyValue: '<?lvEmptyValue',
            options: '<?lvOptions',
            buttonData: '<?lvSelectListButton',
            ngDisabled: '<?ngDisabled',
        }
    }
}

SelectController.$inject = [
    '$scope',
    '$element',
    '$attrs',
    '$compile',
    '$document',
    '$mdUtil',
    '$window',
    'InputContainerStateManager',
    'domUtils'];
function SelectController(
    $scope,
    $element,
    $attrs,
    $compile,
    $document,
    $mdUtil,
    $window,
    InputContainerStateManager,
    domUtils) {

    this.$onInit = $onInit;
    this.$postLink = $postLink;
    this.$onChanges = $onChanges;
    this.$onDestroy = $onDestroy;

    this.toggleDropdown = toggleDropdown;
    this.itemClick = itemClick;
    this.getViewValue = getViewValue;

    this.isOpened = false;
    this.itemHeight = 40;
    this.dropdownHeight = 0;
    this.selectedItem = null;
    this.focusedIndex = -1;
    this.dropdownLeft = 0;
    this.dropdownStyle = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        borderWidth: 1
    };

    this.showSelectListButton = false;

    const maxVisibleItemsDefault = 6;
    const paddingTopBottom = 16;
    const keyCode = {
        ENTER: 13,
        ESCAPE: 27,
        UP: 38,
        DOWN: 40
    };

    let self = this;
    let valueElement = null;
    let dropdownElement = null;
    let isOpened = false;
    let visibleItemsNumber;
    let backdrop = null;
    const containerSizes = {
        minWidth: null,
        maxWidth: null,
        maxItemWidth: null
    };


    let selectListButtonHeight = 0;

    function $onInit() {
        $element.attr('role', 'button');
        $element.attr('aria-haspopup', "listbox");
        $element.addClass('lv-select-vr');
        self.ngModel.$render = render;
        valueElement = $element.find('.lv-select-vr__value');

        $element.on('keydown', onViewKeyDown);
        $element.on('click', toggleDropdown);

        if (self.options) {
            containerSizes.minWidth = self.options.minWidth ?? null;
            containerSizes.maxWidth = self.options.maxWidth ?? null;
        }
    }

    function $onChanges(changes) {
        if (changes.buttonData) {
            if (self.buttonData && angular.isObject(self.buttonData)) {
                self.showSelectListButton = true;
                selectListButtonHeight = 57;
            }
        }
        if (changes.items) {
            if (self.items) {
                visibleItemsNumber = self.maxVisibleItems || maxVisibleItemsDefault;
                const currentVisibleItemsNumber = Math.min(self.items.length, visibleItemsNumber);
                self.dropdownStyle.height = currentVisibleItemsNumber * self.itemHeight + paddingTopBottom + selectListButtonHeight;
                render();
            }
        }
    }

    function $postLink() {

        $element.attr('tabindex', $attrs.tabIndex || '0');

        let inputContainerStateManager = new InputContainerStateManager($element, $attrs,
            self.ngModel, self.inputContainerAdapter);

        domUtils.observeFocusState($element,
            function() {
                if (!self.isDisabled) {
                    inputContainerStateManager.setFocused(true);
                }
            },
            function() {
                $element.blur();
                inputContainerStateManager.setFocused(false);
            });

        self.ngModel.$isEmpty = function(value) {
            return value == null;
        };
    }

    function ensureDropdownCreated() {
        if (!dropdownElement){
            let template = `
                <div class='lv-select-vr__dropdown'
                     ng-style="ctrl.dropdownStyle"
                     lv-focus-method='ctrl.focusDropdown'
                     tabindex="0">
                    <div class='lv-select-vr__dropdown-container'>
                        <md-virtual-repeat-container md-virtual-repeat-container-api='ctrl.virtualRepeatContainer'
                                                     lv-virtual-repeat-size-method="ctrl.setHeight">
                            <div class="lv-select-vr__item"
                                 id="select-item_value-{{item[ctrl.valueProperty]}}" 
                                 role="option"
                                 value="{{item[ctrl.valueProperty]}}"
                                 ng-attr-selected="{{item === ctrl.selectedItem ? 'selected' : undefined}}"
                                 md-virtual-repeat='item in ctrl.items'
                                 md-item-size='ctrl.itemHeight'
                                 ng-class='{"lv-select-vr__item--is-selected": item === ctrl.selectedItem, "lv-select-vr__item--is-focused": $index === ctrl.focusedIndex}'
                                 ng-click='ctrl.itemClick(item)'>
                                 {{ctrl.getViewValue(item)}}
                            </div>
                        </md-virtual-repeat-container>
                        <select-list-button ng-if='ctrl.showSelectListButton' 
                                            button-text='ctrl.buttonData.text' 
                                            button-id='ctrl.buttonData.id'
                                            button-fn='ctrl.buttonData.onClickFn()'>
                        </select-list-button>
                    </div>
                </div>
            `;
            dropdownElement = angular.element(template);
            dropdownElement.hide();
            getBody().append(dropdownElement)
            $compile(dropdownElement)($scope);

            if (self.options) {
                // Measure max width up to 10 elements
                const itemsCount = Math.min(self.items.length, 10);
                containerSizes.maxItemWidth = self.items
                    .map(item => self.getViewValue(item))
                    .sort((a, b) => b.length - a.length)
                    .slice(0, itemsCount)
                    .map(itemText => {
                        const constContainer = angular.element('<div class="lv-select-vr__item" style="position: absolute; opacity: 0;">' + itemText + '</div>');
                        $element.append(constContainer);
                        let result = constContainer[0].getBoundingClientRect().width;
                        constContainer.remove();
                        return result;
                    })
                    .sort((a, b) => b - a)[0];

                // Additional width to compensate scroller width or borders
                containerSizes.maxItemWidth += self.items.length > visibleItemsNumber ? 16 : 4;
            }
        }
    }

    function $onDestroy() {
        $element.off('keydown', onViewKeyDown);
        if (dropdownElement) {
            dropdownElement.remove();
        }
        if (backdrop) {
            backdrop.remove();
            backdrop = null;
        }
    }

    function toggleDropdown() {
        if (isOpened) {
            closeDropdown();
        } else {
            openDropdown();
        }
    }

    function openDropdown() {

        if (!self.items || self.ngDisabled) {
            return;
        }

        $onChanges(self);

        isOpened = true;
        ensureDropdownCreated()

        let containerWidth = getElementRect().width;
        if (containerSizes.minWidth && containerWidth < containerSizes.minWidth) {
            containerWidth = containerSizes.minWidth;
        }
        if (containerSizes.maxItemWidth && containerWidth < containerSizes.maxItemWidth) {
            containerWidth = containerSizes.maxItemWidth;
        }
        if (containerSizes.maxWidth && containerWidth > containerSizes.maxWidth) {
            containerWidth = containerSizes.maxWidth;
        }
        self.dropdownStyle.width = containerWidth;

        backdrop = $mdUtil.createBackdrop($scope, "md-click-catcher  md-backdrop--transparent");
        getBody().append(backdrop);

        dropdownElement.show();

        const elementRect = getElementRect();

        const windowBottom = $window.innerHeight;
        const dropdownBottom = elementRect.top + self.dropdownStyle.height;
        let shiftedItems = 0;
        let topShift = 0;

        if (dropdownBottom > windowBottom){
            const pxShift = dropdownBottom - windowBottom;
            shiftedItems = Math.ceil(pxShift / self.itemHeight);
            topShift = -shiftedItems * self.itemHeight;
        }

        self.dropdownStyle.top = elementRect.top + topShift;
        self.dropdownStyle.left = elementRect.left;
        self.focusDropdown();
        self.setHeight(self.dropdownStyle.height + (self.dropdownStyle.borderWidth * 2) - selectListButtonHeight);

        $mdUtil.nextTick(function() {
            const selectedIndex = self.items.indexOf(self.selectedItem);
            if (selectedIndex >= 0) {
                self.virtualRepeatContainer.scrollToIndex(selectedIndex - shiftedItems);
                self.focusedIndex = selectedIndex;
            }
        });


        bindDropdownEvents();
    }

    function closeDropdown() {
        isOpened = false;
        if (dropdownElement) {
            dropdownElement.hide();
        }
        unbindDropdownEvents();
        if (backdrop) {
            backdrop.remove();
            backdrop = null;
        }
        self.ngModel.$setTouched();
    }

    function itemClick(item) {
        self.selectedItem = item;
        closeDropdown();
        setValue();
    }


    function getElementRect() {
        return $element[0].getBoundingClientRect();
    }

    $window.addEventListener("resize", (event) => {
        closeDropdown();
    });

    function getViewValue(item) {
        if (!item){
            return null;
        }
        let viewValue = item[self.viewProperty];
        if (viewValue){
            return viewValue;
        }
        return item.toString();
    }

    function getBody() {
        return angular.element($document[0].body);
    }

    function setValue() {
        self.ngModel.$setViewValue(self.selectedItem[self.valueProperty]);
    }

    function render() {
        if (self.items){
            const itemId = self.ngModel.$viewValue;
            self.selectedItem = self.items.find(item => {
                return item[self.valueProperty] === itemId;
            });
            if (!self.selectedItem && self.emptyValue) {
                self.selectedItem = self.emptyValue
            }
        }
    }

    function bindDropdownEvents() {
        $mdUtil.nextTick(() => {
            $document.on('click', closeDropdown);
            dropdownElement.on('keydown', onDropdownKeydown)
        });
    }

    function unbindDropdownEvents() {
        $document.off('click', closeDropdown);
        if (dropdownElement) {
            dropdownElement.off('keydown', onDropdownKeydown)
        }
    }

    function onDropdownKeydown(event) {
        event.preventDefault();

        $scope.$apply(() => {
            switch (event.keyCode) {
                case keyCode.UP:
                    moveFocus(-1);
                    break;
                case keyCode.DOWN:
                    moveFocus(1);
                    break;
                case keyCode.ENTER:
                    self.selectedItem = self.items[self.focusedIndex];
                    setValue();
                    closeDropdown();
                    break;
                case keyCode.ESCAPE:
                    closeDropdown();
                    break;
            }
        });
    }

    function onViewKeyDown(event) {
        switch (event.keyCode) {
            case keyCode.ENTER:
                $scope.$apply(() => {
                    openDropdown();
                });
                break;
        }
    }

    function moveFocus(shift) {

        const newIndex = self.focusedIndex + shift;

        if (newIndex < 0 || newIndex >= self.items.length) {
            return;
        }

        self.focusedIndex = newIndex;

        const firstVisibleItemIndex = self.virtualRepeatContainer.scrollOffset / self.itemHeight;
        const lastVisibleItemIndex = Math.min(firstVisibleItemIndex + visibleItemsNumber - 1, self.items.length - 1);

        if (self.focusedIndex < firstVisibleItemIndex) {
            self.virtualRepeatContainer.scrollToIndex(firstVisibleItemIndex - 1);
        } else if (self.focusedIndex > lastVisibleItemIndex) {
            self.virtualRepeatContainer.scrollToIndex(firstVisibleItemIndex + 1);
        }
    }
}

export default {
    type: 'directive',
    name: 'lvSelect',
    value: selectDirective
};
