/**
 * Created by galkina on 13.08.24.
 */
export const constTaxType = [
    {
        id: 'DEFAULT',
        name: 'SETTINGS.TAX.VAT'
    }, {
        id: 'EXCISE',
        name: 'SETTINGS.TAX.EXCISE'
    }
];
