/**
 * Created by galkina on 13.08.24.
 */
export const constTaxSaVATExemptReason = {

    ZERO_RATED_GOODS: [
        'VATEX-SA-32',
        'VATEX-SA-33',
        'VATEX-SA-34-1',
        'VATEX-SA-34-2',
        'VATEX-SA-34-3',
        'VATEX-SA-34-4',
        'VATEX-SA-34-5',
        'VATEX-SA-35',
        'VATEX-SA-36',
        'VATEX-SA-EDU',
        'VATEX-SA-HEA',
        'VATEX-SA-MLTRY'
    ],

    EXEMPT_FROM_TAX: [
        'VATEX-SA-29',
        'VATEX-SA-29-7',
        'VATEX-SA-30'
    ]
};
