/**
 * Created by galkina on 15.08.24.
 */
export const constTaxRatePredefined = {
    VAT: [
        15,
        5,
        0
    ],
    EXCISE: [
        100,
        50
    ]
};